/*
 * @Author: sungang
 * @Date: 2020-11-24 10:16:36
 * @LastEditors: chenlei
 * @LastEditTime: 2021-09-22 14:37:07
 * @Description:
 */
export default [
  {
    path: '/mdm_center',
    name: 'mdm',
    meta: {
      title: 'mdm',
    },
    redirect: '/mdm_center/parameter_configuration',
    component: { render: (e) => e('router-view') },
    children: [
      {
        path: 'terminal_stop_apply',
        name: 'terminal_stop_apply',
        component: () => import(/* webpackChunkName: "config_center/terminal_stop_apply" */ '../views/terminal_stop_apply'),
        meta: { title: '客户终止申请' },
      },
      {
        path: 'company_body',
        name: 'company_body',
        component: () => import(/* webpackChunkName: "config_center/company_body" */ '../views/company_body'),
        meta: { title: '公司主体' },
      },
      {
        path: 'queue_manage',
        name: 'queue_manage',
        component: () => import(/* webpackChunkName: "config_center/queue_manage" */ '../views/queue_manage'),
        meta: { title: '队列管理' },
      },
      {
        path: 'team_manage',
        name: 'team_manage',
        component: () => import(/* webpackChunkName: "config_center/team_manage" */ '../views/team_manage'),
        meta: { title: '团队管理' },
      },
      {
        path: 'bank_count',
        name: 'bank_count',
        component: () => import(/* webpackChunkName: "config_center/bank_count" */ '../views/bank_count'),
        meta: { title: '银行账户' },
      },
      {
        path: 'new_product_apply',
        name: 'new_product_apply',
        component: () => import(/* webpackChunkName: "config_center/new_product_apply" */ '../views/new_product_apply'),
        meta: { title: '新产品开发申请' },
      },
      {
        path: 'product_adjustment',
        name: 'product_adjustment',
        component: () => import(/* webpackChunkName: "config_center/new_product_apply" */ '../views/product_adjustment'),
        meta: { title: '产品微调申请表' },
      },
      {
        path: 'specialty_store',
        name: 'specialty_store',
        component: () => import(/* webpackChunkName: "config_center/specialty_store" */ '../views/specialty_store'),
        meta: { title: '专卖店申请' },
      },
      {
        path: 'terminal_audit',
        name: 'terminal_audit',
        component: () => import(/* webpackChunkName: "config_center/terminal_audit" */ '../views/terminal_audit'),
        meta: { title: '终端审批' },
      },
      {
        path: 'parameter_configuration',
        name: 'mdm_parameter_configuration',
        component: () => import(
          /* webpackChunkName: "config_center/parameter_configuration" */ '../views/parameter_configuration'
        ),
        meta: { title: '全局参数配置' },
      },
      {
        path: 'dict',
        name: 'config_mdm_dict',
        component: () => import(/* webpackChunkName: "config_center/dict" */ '../views/dict'),
        meta: { title: '数据字典' },
      },
      {
        path: 'rule_coding',
        name: 'mdm_rule_coding',
        component: () => import(/* webpackChunkName: "config_center/rule_coding" */ '../views/rule_coding'),
        meta: { title: '规则编码' },
      },
      {
        path: 'organization_management',
        name: 'mdm_organization_management',
        component: () => import(
          /* webpackChunkName: "mdm_center/organization_management" */ '../views/organization_management'
        ),
        meta: { title: '组织管理' },
      },
      {
        path: 'user_management',
        name: 'mdm_user_management',
        component: () => import(/* webpackChunkName: "mdm_center/user_management" */ '../views/user_management'),
        meta: { title: '用户管理' },
      },
      {
        path: 'user_client_management',
        name: 'mdm_user_client_management',
        component: () => import(
          /* webpackChunkName: "mdm_center/user_client_management" */ '../views/user_client_management'
        ),
        meta: { title: '客户用户管理' },
      },
      {
        path: 'district_area',
        name: 'mdm_district_area',
        component: () => import(/* webpackChunkName: "mdm_center/district_area" */ '../views/district_area'),
        meta: { title: '行政区域' },
      },
      {
        path: 'product_hierarchy',
        name: 'mdm_product_hierarchy',
        component: () => import(
          /* webpackChunkName: "mdm_center/product_hierarchy" */ '../views/product_hierarchy'
        ),
        meta: { title: '产品层级' },
      },
      {
        path: 'position_management',
        name: 'mdm_position_management',
        component: () => import(
          /* webpackChunkName: "mdm_center/position_level_management" */ '../views/position_level_management'
        ),
        meta: { title: '职位级别' },
      },
      {
        path: 'products_management',
        name: 'mdm_products_management',
        component: () => import(
          /* webpackChunkName: "mdm_center/products_management" */ '../views/products_management'
        ),
        meta: { title: '商品管理' },
      },
      {
        path: 'customer_information',
        name: 'mdm_customer_information',
        component: () => import(
          /* webpackChunkName: "mdm_center/customer_information" */ '../views/customer_information'
        ),
        meta: { title: '客户信息' },
      },
      {
        path: 'material_management',
        name: 'mdm_material_management',
        component: () => import(
          /* webpackChunkName: "mdm_center/material_management" */ '../views/material_management'
        ),
        meta: { title: '物料管理' },
      },
      {
        path: 'material_group_management',
        name: 'mdm_material_group_management',
        component: () => import(
          /* webpackChunkName: "mdm_center/material_management" */ '../views/material_group_management'
        ),
        meta: { title: '物料组管理' },
      },
      {
        path: 'role_manage',
        name: 'mdm_role_manage',
        component: () => import(/* webpackChunkName: "mdm_center/role_manage" */ '../views/role_manage'),
        meta: { title: '角色管理' },
      },
      {
        path: 'position_information',
        name: 'mdm_position_information',
        component: () => import(
          /* webpackChunkName: "mdm_center/position_information" */ '../views/position_information'
        ),
        meta: { title: '职位信息' },
      },
      {
        path: 'condition_classification',
        name: 'mdm_condition_classification',
        component: () => import(
          /* webpackChunkName: "mdm_center/condition_classification" */ '../views/condition_classification'
        ),
        meta: { title: '条件字段分类' },
      },
      {
        path: 'condition_stub',
        name: 'mdm_condition_stub',
        component: () => import(/* webpackChunkName: "mdm_center/condition_stub" */ '../views/condition_stub'),
        meta: { title: '条件类型' },
      },
      {
        path: 'terminal_info',
        name: 'mdm_terminal_info',
        component: () => import(/* webpackChunkName: "mdm_center/terminal_info" */ '../views/terminal_info'),
        meta: { title: '终端信息' },
      },
      {
        path: 'dealer_related_information_report',
        name: 'mdm_dealer_related_information_report',
        component: () => import(/* webpackChunkName: "mdm_center/dealer_related_information_report" */ '../views/dealer_related_information_report'),
        meta: { title: '经销商关联信息报表' },
      },
      {
        path: 'terminal_associated_scanning_data',
        name: 'mdm_terminal_associated_scanning_data',
        component: () => import(/* webpackChunkName: "mdm_center/terminal_associated_scanning_data" */ '../views/terminal_associated_scanning_data'),
        meta: { title: '终端关联扫码数据' },
      },
      {
        path: 'dealer_associated_scanning_data',
        name: 'mdm_dealer_associated_scanning_data',
        component: () => import(/* webpackChunkName: "mdm_center/dealer_associated_scanning_data" */ '../views/dealer_associated_scanning_data'),
        meta: { title: '经销商关联扫码数据' },
      },
      {
        path: 'price_maintain',
        name: 'price_maintain',
        component: () => import(/* webpackChunkName: "mdm_center/price_maintain" */ '../views/price_maintain'),
        meta: { title: '价格维护' },
      },
      {
        path: 'product_price_maintain',
        name: 'product_price_maintain',
        component: () => import(/* webpackChunkName: "mdm_center/product_price_maintain" */ '../views/product_price_maintain'),
        meta: { title: '直营产品价格维护' },
      },
      {
        path: 'data_auth',
        name: 'data_auth',
        component: () => import(/* webpackChunkName: "mdm_center/data_auth" */ '../views/data_auth'),
        meta: { title: '数据权限' },
      },
      {
        path: 'webscoket',
        name: 'webscoket',
        component: () => import(/* webpackChunkName: "mdm_center/webscoket" */ '../views/webscoket'),
        meta: { title: 'webscoket' },
      },
      {
        path: 'process_role',
        name: '流程角色',
        component: () => import(/* webpackChunkName: "mdm_center/process_role" */ '../views/process_role'),
        meta: { title: '流程角色' },
      },
      {
        path: 'customer_org',
        name: 'customer_org',
        component: () => import(/* webpackChunkName: "mdm_center/customer_org" */ '../views/customer_org'),
        meta: { title: '客户组织' },
      },
      {
        path: 'customer_meterial',
        name: 'customer_meterial',
        component: () => import(
          /* webpackChunkName: "mdm_center/customer_meterial" */ '../views/customer_meterial'
        ),
        meta: { title: '客户物料' },
      },
      {
        path: 'announcement_manage',
        name: 'mdm_announcement_manage',
        component: () => import(
          /* webpackChunkName: "mdm_center/announcement_manage" */ '../views/announcement_manage'
        ),
        meta: { title: '公告管理' },
      },
      {
        path: 'bug',
        name: 'bug',
        component: () => import(/* webpackChunkName: "mdm_center/announcement_manage" */ '../views/bug'),
        meta: { title: 'BUG管理' },
      },
      {
        path: 'demo',
        name: 'demo',
        component: () => import(/* webpackChunkName: "mdm_center/demo" */ '../views/demo'),
        meta: { title: 'demo' },
      },
      {
        path: 'user_terminal_management',
        name: 'user_terminal_management',
        component: () => import(
          /* webpackChunkName: "mdm_center/user_terminal_management" */ '../views/user_terminal_management'
        ),
        meta: { title: '终端信息' },
      },
      {
        path: 'gaode_district_area',
        name: 'gaode_district_area',
        component: () => import(
          /* webpackChunkName: "mdm_center/gaode_district_area" */ '../views/gaode_district_area'
        ),
        meta: { title: '高德POI行政区域' },
      },
      {
        path: 'gaode_district_type',
        name: 'gaode_district_type',
        component: () => import(
          /* webpackChunkName: "mdm_center/gaode_district_type" */ '../views/gaode_district_type'
        ),
        meta: { title: '高德POI行政区域' },
      },
      {
        path: 'gaode_amap_poi',
        name: 'gaode_amap_poi',
        component: () => import(
          /* webpackChunkName: "mdm_center/gaode_district_type" */ '../views/gaode_amap_poi'
        ),
        meta: { title: '高德POI列表' },
      },
      {
        path: 'log',
        name: 'log',
        component: () => import(/* webpackChunkName: "mdm_center/log" */ '../views/log'),
        meta: { title: 'MQ日志' },
      },
      {
        path: 'customer_distribution',
        name: 'customer_distribution',
        component: () => import(
          /* webpackChunkName: "mdm_center/customer_distribution" */ '../views/customer_distribution'
        ),
        meta: { title: '客户分布' },
      },
      {
        path: 'knowledge_base',
        name: 'knowledge_base',
        component: () => import(
          /* webpackChunkName: "mdm_center/knowledge_base" */ '../views/knowledge_base'
        ),
        meta: { title: '知识库' },
      },
    ],
  },
];
