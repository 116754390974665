/*
 * @Author: yzr
 * @Date: 2020-11-23 15:13:14
 * @LastEditors: chenlei
 * @LastEditTime: 2021-09-22 15:01:38
 */
export default [
  {
    path: '/dms_manage',
    name: 'dms',
    meta: {
      title: 'DMS',
    },
    component: { render: (e) => e('router-view') },
    children: [
      {
        path: 'ads_manage',
        name: 'ads_manage',
        component: () => import(/* webpackChunkName: "dms_manage/ads_manage" */ '../views/ads_manage'),
        meta: { title: '广告管理' },
      },
      {
        path: 'avail_rule',
        name: 'avail_rule',
        component: () => import(/* webpackChunkName: "dms_manage/avail_rule" */ '../views/available/avail_rule'),
        meta: { title: '可购规则配置' },
      },
      {
        path: 'avail_custom',
        name: 'avail_custom',
        component: () => import(
          /* webpackChunkName: "dms_manage/avail_custom" */ '../views/available/avail_custom'
        ),
        meta: { title: '客户可购清单' },
      },
      {
        path: 'avail_terminal',
        name: 'avail_terminal',
        component: () => import(
          /* webpackChunkName: "dms_manage/avail_terminal" */ '../views/available/avail_terminal'
        ),
        meta: { title: '终端可购清单' },
      },
      {
        path: 'contract',
        name: 'contract',
        component: () => import(/* webpackChunkName: "dms_manage/contract" */ '../views/contract/contract'),
        meta: { title: '合同管理' },
      },
      {
        path: 'contract_manage',
        name: 'contract_manage',
        component: () => import(
          /* webpackChunkName: "dms_manage/contract_manage" */ '../views/contract/contract_manage'
        ),
        meta: { title: '合同管理(新)' },
      },
      {
        path: 'contract_element',
        name: 'contract_element',
        component: () => import(
          /* webpackChunkName: "dms_manage/contract_element" */ '../views/contract/contract_element'
        ),
        meta: { title: '合同要素' },
      },
      {
        path: 'contract_template',
        name: 'contract_template',
        component: () => import(
          /* webpackChunkName: "dms_manage/contract_template" */ '../views/contract/contract_template'
        ),
        meta: { title: '合同模板' },
      },
      {
        path: 'target',
        name: 'target',
        component: () => import(/* webpackChunkName: "dms_manage/target" */ '../views/contract/target'),
        meta: { title: '销售目标' },
      },
      {
        path: 'target_two',
        name: 'target_two',
        component: () => import(/* webpackChunkName: "dms_manage/target_two" */ '../views/contract/target_two'),
        meta: { title: '销售目标' },
      },
      {
        path: 'cost_pool_scale',
        name: 'cost_pool_scale',
        component: () => import(/* webpackChunkName: "dms_manage/cost_pool_scale" */ '../views/cost_pool_scale'),
        meta: { title: '费用池使用比例' },
      },
      {
        path: 'budget_adjustment',
        name: 'budget_adjustment',
        component: () => import(
          /* webpackChunkName: "dms_manage/budget_adjustment" */ '../views/budget_adjustment'
        ),
        meta: { title: '预算调整' },
      },
      {
        path: '/dms_manage/customer_interaction',
        name: 'customer_interaction',
        meta: {
          title: '客户互动',
        },
        redirect: '/dms_manage/customer_interaction/notice_manage',
        component: { render: (e) => e('router-view') },
        children: [
          {
            path: 'notice_manage',
            name: 'notice_manage',
            component: () => import(
              /* webpackChunkName: "dms_manage/notice_manage" */ '../views/customer_interaction/notice_manage'
            ),
            meta: { title: '公告管理' },
          },
          {
            path: 'company_info',
            name: 'company_info',
            component: () => import(
              /* webpackChunkName: "dms_manage/company_info" */ '../views/customer_interaction/company_info'
            ),
            meta: { title: '公司资料' },
          },
          {
            path: 'complaint',
            name: 'complaint',
            component: () => import(/* webpackChunkName: "dms_manage/complaint" */ '../views/complaint'),
            meta: { title: '投诉建议' },
          },
        ],
      },
      {
        path: '/dms_manage/record_manage',
        name: 'record_manage',
        meta: {
          title: '对账管理',
        },
        redirect: '/dms_manage/record_manage/discount_cost_pool',
        component: { render: (e) => e('router-view') },
        children: [
          {
            path: 'discount_cost_record',
            name: 'discount_cost_record',
            component: () => import(
              /* webpackChunkName: "record_manage/discount_cost_record" */ '../views/record_manage/discount_cost_record'
            ),
            meta: { title: '费用池操作记录' },
          },
          {
            path: 'szmx_operation_record',
            name: 'szmx_operation_record',
            component: () => import(
              /* webpackChunkName: "record_manage/discount_cost_record" */ '../views/record_manage/szmx_operation_record'
            ),
            meta: { title: '费用池上账明细操作记录' },
          },
          {
            path: 'discount_cost_pool',
            name: 'discount_cost_pool',
            component: () => import(
              /* webpackChunkName: "record_manage/discount_cost_pool" */ '../views/record_manage/discount_cost_pool'
            ),
            meta: { title: '折扣费用池' },
          },
          {
            path: 'discount_goods',
            name: 'discount_goods',
            component: () => import(
              /* webpackChunkName: "record_manage/discount_goods" */ '../views/record_manage/discount_goods'
            ),
            meta: { title: '货补费用池' },
          },
          {
            path: 'discount_cost_pool_detail',
            name: 'discount_cost_pool_detail',
            component: () => import(
              /* webpackChunkName: "record_manage/discount_cost_pool_detail" */ '../views/record_manage/discount_cost_pool/form/components/condition_table.vue'
            ),
            meta: { title: '折扣费用池上账明细' },
          },
          {
            path: 'discount_goods_detail',
            name: 'discount_goods_detail',
            component: () => import(
              /* webpackChunkName: "record_manage/discount_goods_detail" */ '../views/record_manage/discount_goods/form/components/condition_table.vue'
            ),
            meta: { title: '货补费用池上账明细' },
          },
          {
            path: 'discount_cost_pool_operation',
            name: 'discount_cost_pool_operation',
            component: () => import(
              /* webpackChunkName: "record_manage/discount_cost_pool_operation" */ '../views/record_manage/discount_cost_pool/form/components/condition_detail_table.vue'
            ),
            meta: { title: '折扣费用池操作记录' },
          },
          {
            path: 'discount_goods_operation',
            name: 'discount_goods_operation',
            component: () => import(
              /* webpackChunkName: "record_manage/discount_goods_operation" */ '../views/record_manage/discount_goods/form/components/condition_detail_table.vue'
            ),
            meta: { title: '货补费用池操作记录' },
          },
          {
            path: 'discount_cost_pool_examine',
            name: 'discount_cost_pool_examine',
            component: () => import(
              /* webpackChunkName: "record_manage/discount_cost_pool_examine" */ '../views/record_manage/discount_cost_pool_examine'
            ),
            meta: { title: '产品折扣调整审批' },
          },
          {
            path: 'discount_goods_examine',
            name: 'discount_goods_examine',
            component: () => import(
              /* webpackChunkName: "record_manage/discount_goods_examine" */ '../views/record_manage/discount_goods_examine'
            ),
            meta: { title: '产品折扣调整审批' },
          },
        ],
      },
      {
        path: '/dms_manage/rebate_manage',
        name: 'rebate_manage',
        meta: {
          title: '返利管理',
        },
        redirect: '/dms_manage/rebate_manage/rebate_config',
        component: { render: (e) => e('router-view') },
        children: [
          {
            path: 'rebate_config',
            name: 'rebate_config',
            component: () => import(
              /* webpackChunkName: "rebate_manage/rebate_config" */ '../views/rebate/rebate_config'
            ),
            meta: { title: '返利配置' },
          },
          {
            path: 'rebate_policy',
            name: 'rebate_policy',
            component: () => import(
              /* webpackChunkName: "rebate_manage/rebate_policy" */ '../views/rebate/rebate_policy'
            ),
            meta: { title: '返利政策' },
          },
          {
            path: 'rebate_detail',
            name: 'rebate_detail',
            component: () => import(
              /* webpackChunkName: "rebate_manage/rebate_detail" */ '../views/rebate/rebate_detail'
            ),
            meta: { title: '返利政策' },
          },
        ],
      },
      {
        path: '/dms_manage/order_manage',
        name: 'order_manage',
        meta: {
          title: '订单管理',
        },
        redirect: '/dms_manage/order_manage/min_order_quantity',
        component: { render: (e) => e('router-view') },
        children: [
          {
            path: 'min_order_quantity',
            name: 'min_order_quantity',
            component: () => import(
              /* webpackChunkName: "order_manage/min_order_quantity" */ '../views/order_manage/min_order_quantity'
            ),
            meta: { title: '起订量' },
          },
          {
            path: 'order_manage',
            name: 'order_info',
            component: () => import(
              /* webpackChunkName: "order_manage/order_manage" */ '../views/order_manage/order_manage'
            ),
            meta: { title: '订单管理' },
          },
          {
            path: 'order_manage_new',
            name: 'order_manage_new',
            component: () => import(
              /* webpackChunkName: "order_manage/order_manage" */ '../views/order_manage/order_manage_new'
            ),
            meta: { title: '订单管理(新)' },
          },
          {
            path: 'inventory_statistics',
            name: 'inventory_statistics',
            component: () => import(
              /* webpackChunkName: "order_manage/inventory_statistics" */ '../views/order_manage/inventory_statistics'
            ),
            meta: { title: '库存统计' },
          },
          {
            path: 'remote_code_scanning_report',
            name: 'remote_code_scanning_report',
            component: () => import(
              /* webpackChunkName: "order_manage/remote_code_scanning_report" */ '../views/order_manage/remote_code_scanning_report'
            ),
            meta: { title: '异地扫码报备' },
          },
        ],
      },
      {
        path: '/dms_manage/reward_manage',
        name: 'reward_manage',
        meta: {
          title: '奖励管理',
        },
        redirect: '/dms_manage/reward_manage/incentive_budget',
        component: { render: (e) => e('router-view') },
        children: [
          {
            path: 'incentive_budget',
            name: 'incentive_budget',
            component: () => import(
              /* webpackChunkName: "reward_manage/incentive_budget" */ '../views/reward_manage/incentive_budget'
            ),
            meta: { title: '奖励预算' },
          },
          {
            path: 'award_application',
            name: 'award_application',
            component: () => import(
              /* webpackChunkName: "reward_manage/award_application" */ '../views/reward_manage/award_application'
            ),
            meta: { title: '奖励申请' },
          },
        ],
      },
      {
        path: '/dms_manage/transit_dealer_management',
        name: 'transit_dealer_management',
        meta: {
          title: '中转经销商管理',
        },
        redirect: '/dms_manage/transit_dealer_management/transit_distributor',
        component: { render: (e) => e('router-view') },
        children: [
          {
            path: 'transit_distributor',
            name: 'transit_distributor',
            component: () => import(
              /* webpackChunkName: "transit_dealer_management/transit_distributor" */ '../views/transit_dealer_management/transit_distributor'
            ),
            meta: { title: '中转经销商列表' },
          },
          {
            path: 'terminal_orders',
            name: 'terminal_orders',
            component: () => import(
              /* webpackChunkName: "transit_dealer_management/terminal_orders" */ '../views/transit_dealer_management/terminal_orders'
            ),
            meta: { title: '中转订单列表' },
          },
        ],
      },
      {
        path: 'promotion_policy',
        name: 'promotion_policy',
        component: () => import(
          /* webpackChunkName: "dms_manage/pro_policy" */ '../views/promotion/pro_policy-copy'
        ),
        meta: { title: '促销政策' },
      },
      {
        path: 'promotion_policy_new',
        name: 'promotion_policy_new',
        component: () => import(/* webpackChunkName: "dms_manage/pro_policy" */ '../views/promotion/pro_policy'),
        meta: { title: '促销政策' },
      },
      {
        path: 'promotion_rule',
        name: 'promotion_rule',
        component: () => import(/* webpackChunkName: "dms_manage/pro_rule" */ '../views/promotion/pro_rule'),
        meta: { title: '促销引擎' },
      },
      {
        path: 'log',
        name: 'promotion_log',
        component: () => import(/* webpackChunkName: "dms_manage/log" */ '../views/log'),
        meta: { title: '日志' },
      },
      {
        path: 'log_view',
        name: 'log_view',
        component: () => import(/* webpackChunkName: "dms_manage/log_view" */ '../views/log_view'),
        meta: { title: '日志查看' },
      },
      {
        path: 'level_bg_config',
        name: 'level_bg_config',
        component: () => import(/* webpackChunkName: "dms_manage/log_view" */ '../views/level_bg_config/index.js'),
        meta: { title: '档级背景配置' },
      },
      {
        path: 'home_level_image_config',
        name: 'home_level_image_config',
        component: () => import(
          /* webpackChunkName: "dms_manage/log_view" */ '../views/home_level_image_config/index.js'
        ),
        meta: { title: '首页档级权益图片配置' },
      },
      {
        path: 'equity_manage_rule',
        name: 'equity_manage_rule',
        component: () => import(
          /* webpackChunkName: "dms_manage/log_view" */ '../views/equity_manage_rule/index.js'
        ),
        meta: { title: '权益管理规则' },
      },
    ],
  },
];
