export default [
  {
    path: '/tpm_center/fee_regular',
    name: 'tpm',
    meta: {
      title: '费用规则中心',
    },
    component: { render: (e) => e('router-view') },
    children: [
      {
        path: 'standard_protect',
        name: 'standard_protect',
        component: () => import(/* webpackChunkName: "fee_regular/standard_protect" */ '../views/fee_regular/standard_protect'),
        meta: { title: '费用计算标准维护' },
      },
      {
        path: 'fee_rule',
        name: 'fee_rule',
        component: () => import(/* webpackChunkName: "fee_regular/standard_protect" */ '../views/fee_regular/fee_rule'),
        meta: { title: '费用规则维护明细表' },
      },
      {
        path: 'quota_control',
        name: 'quota_control',
        component: () => import(/* webpackChunkName: "fee_regular/quota_control" */ '../views/fee_regular/quota_control'),
        meta: { title: '费用额度管控池' },
      },
      {
        path: 'fee_rule_report',
        name: 'fee_rule_report',
        component: () => import(/* webpackChunkName: "fee_regular/fee_rule_report" */ '../views/fee_regular/fee_rule_report'),
        meta: { title: '费用规则维护表' },
      },
      {
        path: 'DaShan_budget_rules',
        name: 'DaShan_budget_rules',
        component: () => import(/* webpackChunkName: "fee_regular/DaShan_budget_rules" */ '../views/fee_regular/DaShan_budget_rules'),
        meta: { title: '大商预算规则' },
      },
    ],
  },

  {
    path: '/tpm_center/budget',
    name: 'tpm',
    meta: {
      title: '预算中心',
    },
    component: { render: (e) => e('router-view') },
    children: [

      {
        path: 'extra_cost',
        name: 'extra_cost',
        component: () => import(/* webpackChunkName: "budget/extra_cost" */ '../views/budget/extra_cost'),
        meta: { title: '额外费用申请' },
      },
      {
        path: 'pre_investment',
        name: 'pre_investment',
        component: () => import(/* webpackChunkName: "budget/pre_investment" */ '../views/budget/pre_investment'),
        meta: { title: '市场预投入申请' },
      },
      {
        path: 'fiscal_year_set',
        name: 'fiscal_year_set',
        component: () => import(
          /* webpackChunkName: "budget/fiscal_year_set" */ '../views/budget/fiscal_year_set'
        ),
        meta: { title: '财年设置' },
      },
      {
        path: 'budget_subjects',
        name: 'budget_subjects',
        component: () => import(
          /* webpackChunkName: "budget/budget_subjects" */ '../views/budget/budget_subjects'
        ),
        meta: { title: '预算科目' },
      },
      {
        path: 'categories',
        name: 'categories',
        component: () => import(/* webpackChunkName: "budget/categories" */ '../views/budget/categories'),
        meta: { title: '活动大类' },
      },
      {
        path: 'budget_fine',
        name: 'budget_fine',
        component: () => import(/* webpackChunkName: "budget/finelines" */ '../views/budget/finelines'),
        meta: { title: '活动细类' },
      },
      {
        path: 'budget_audit_collect',
        name: 'budget_audit_collect',
        component: () => import(/* webpackChunkName: "budget/audit_collect" */ '../views/budget/audit_collect'),
        meta: { title: '采集核销示例' },
      },
      {
        path: 'collect_field',
        name: 'collect_field',
        component: () => import(/* webpackChunkName: "budget/collect_field" */ '../views/budget/collect_field'),
        meta: { title: '活动字段采集' },
      },
      {
        path: 'budget_fee',
        name: 'budget_fee',
        component: () => import(/* webpackChunkName: "budget/budget_fee" */ '../views/budget/budget_fee'),
        meta: { title: '费用预算' },
      },
      {
        path: 'budget_fee_carry_forward',
        name: 'budget_fee_carry_forward',
        component: () => import(/* webpackChunkName: "budget/budget_fee_carry_forward" */ '../views/budget/budget_fee_carry_forward'),
        meta: { title: '费用预算结转清单' },
      },
      {
        path: 'budget_fee_lookup',
        name: 'budget_fee_lookup',
        component: () => import(/* webpackChunkName: "budget/budget_fee_lookup" */ '../views/budget/budget_fee_lookup'),
        meta: { title: '经销商预算查询报表' },
      },
      {
        path: 'income',
        name: 'income',
        component: () => import(/* webpackChunkName: "budget/income" */ '../views/budget/income'),
        meta: { title: '收入预算' },
      },
      {
        path: 'DaShang_budget_record',
        name: 'DaShang_budget_record',
        component: () => import(/* webpackChunkName: "budget/DaShang_budget_record" */ '../views/budget/DaShang_budget_record'),
        meta: { title: '大商预算记录' },
      },
      {
        path: 'dealer_fine_records',
        name: 'dealer_fine_records',
        component: () => import(/* webpackChunkName: "budget/dealer_fine_records" */ '../views/budget/dealer_fine_records'),
        meta: { title: '经销商罚款记录' },
      },
      {
        path: 'cost_warning_ratio_configuration',
        name: 'cost_warning_ratio_configuration',
        component: () => import(/* webpackChunkName: "budget/cost_warning_ratio_configuration" */ '../views/budget/cost_warning_ratio_configuration/table'),
        meta: { title: '费用预警比例配制' },
      },
    ],
  },
  {
    path: '/tpm_center/activity',
    name: 'tpm',
    meta: {
      title: '活动中心',
    },
    component: { render: (e) => e('router-view') },
    children: [

      {
        path: 'marketing_activity_close',
        name: 'marketing_activity_close',
        component: () => import(/* webpackChunkName: "activity/marketing_reconsideration" */ '../views/activity/marketing_activity_close'),
        meta: { title: '市场活动关闭' },
      },
      {
        path: 'marketing_reconsideration',
        name: 'marketing_reconsideration',
        component: () => import(/* webpackChunkName: "activity/marketing_reconsideration" */ '../views/activity/marketing_reconsideration'),
        meta: { title: '市场活动复议' },
      },
      {
        path: 'inspection_center',
        name: 'inspection_center',
        component: () => import(/* webpackChunkName: "activity/inspection_center" */ '../views/activity/inspection_center'),
        meta: { title: '督查中心' },
      },
      {
        path: 'marketing_activity',
        name: 'marketing_activity',
        component: () => import(/* webpackChunkName: "activity/marketing_activity" */ '../views/activity/marketing_activity'),
        meta: { title: '市场活动项目' },
      },
      {
        path: 'annual_fuzzy_award',
        name: 'annual_fuzzy_award',
        component: () => import(/* webpackChunkName: "activity/annual_fuzzy_award" */ '../views/activity/annual_fuzzy_award'),
        meta: { title: '年度模糊奖励' },
      },
      {
        path: 'department_receiving',
        name: 'department_receiving',
        component: () => import(/* webpackChunkName: "activity/function_department_receiving" */ '../views/activity/function_department_receiving'),
        meta: { title: '职能部门领用活动' },
      },

      {
        path: 'marketing_promote',
        name: 'marketing_promote',
        component: () => import(/* webpackChunkName: "activity/function_department_receiving" */ '../views/activity/marketing_promote'),
        meta: { title: '市场推广活动项目' },
      },

      {
        path: 'quota',
        name: 'quota',
        component: () => import(/* webpackChunkName: "activity/quota" */ '../views/activity/quota'),
        meta: { title: '定额活动' },
      },
      {
        path: 'practicality',
        name: 'practicality',
        component: () => import(/* webpackChunkName: "activity/practicality" */ '../views/activity/practicality'),
        meta: { title: '实物费用' },
      },
      {
        path: 'project',
        name: 'project',
        component: () => import(/* webpackChunkName: "activity/project" */ '../views/activity/project'),
        meta: { title: '项目活动' },
      },
      {
        path: 'department',
        name: 'department',
        component: () => import(/* webpackChunkName: "activity/department" */ '../views/activity/department'),
        meta: { title: '部门费用' },
      },
      {
        path: 'department_receive',
        name: 'department_receive',
        component: () => import(
          /* webpackChunkName: "activity/department_receive" */ '../views/activity/department_receive'
        ),
        meta: { title: '部门领用' },
      },
      {
        path: 'rebate',
        name: 'rebate',
        component: () => import(/* webpackChunkName: "activity/rebate" */ '../views/activity/rebate'),
        meta: { title: '返利活动' },
      },
      {
        path: 'promotion',
        name: 'promotion',
        component: () => import(/* webpackChunkName: "activity/promotion" */ '../views/activity/promotion'),
        meta: { title: '促销政策' },
      },
      {
        path: 'plan',
        name: 'plan',
        component: () => import(/* webpackChunkName: "activity/plan" */ '../views/activity/plan'),
        meta: { title: '销售计划' },
      },
      {
        path: 'factory_return_tour',
        name: 'factory_return_tour',
        component: () => import(/* webpackChunkName: "activity/factory_return_tour" */ '../views/activity/factory_return_tour'),
        meta: { title: '返厂游活动' },
      },
    ],
  },
  {
    path: '/tpm_center/pay_center',
    name: 'tpm',
    meta: {
      title: '支付中心',
    },
    component: { render: (e) => e('router-view') },
    children: [
      {
        path: 'customer_prepayment',
        name: 'customer_prepayment',
        component: () => import(
          /* webpackChunkName: "pay_center/customer_advance_pay" */ '../views/pay_center/customer_prepayment'
        ),
        meta: { title: '活动预付-客户' },
      },
      {
        path: 'activity_prepayment',
        name: 'activity_prepayment',
        component: () => import(
          /* webpackChunkName: "pay_center/act_advance_pay" */ '../views/pay_center/activity_prepayment'
        ),
        meta: { title: '活动预付-活动' },
      },
      {
        path: 'cost_account',
        name: 'cost_account',
        component: () => import(
          /* webpackChunkName: "pay_center/cost_account" */ '../views/pay_center/cost_account'
        ),
        meta: { title: '费用上账' },
      },
      {
        path: 'write_off',
        name: 'write_off',
        component: () => import(/* webpackChunkName: "pay_center/write_off" */ '../views/pay_center/write_off'),
        meta: { title: '核销申请' },
      },
    ],
  },
  {
    path: '/tpm_center/liquor',
    name: 'tpm',
    meta: {
      title: '酒类费用中心',
    },
    component: { render: (e) => e('router-view') },
    children: [],
  },
  {
    path: 'tpm_center/analysis_report',
    name: 'analysis_report',
    meta: {
      title: '分析报表',
    },
    component: { render: (e) => e('router-view') },
    children: [
      {
        path: 'region_sale',
        name: 'region_sale',
        component: () => import(
          /* webpackChunkName analysis_reportregion_sale */ '../views/analysis_report/region_sale'
        ),
        meta: { title: '区域销售数据' },
      },
      {
        path: 'category_sale',
        name: 'category_sale',
        component: () => import(
          /* webpackChunkName analysis_reportcategory_sale */ '../views/analysis_report/category_sale'
        ),
        meta: { title: '品类销售数据' },
      },
      {
        path: 'monthly_sale',
        name: 'monthly_sale',
        component: () => import(
          /* webpackChunkName analysis_reportmonthly_sale */ '../views/analysis_report/monthly_sale'
        ),
        meta: { title: '月度销售数据' },
      },
      {
        path: 'year_sale',
        name: 'year_sale',
        component: () => import(
          /* webpackChunkName analysis_reportyear_sale */ '../views/analysis_report/year_sale'
        ),
        meta: { title: '年度销售数据分析' },
      },
    ],
  },
  {
    path: '/tpm_center/tasting_wine_management',
    name: 'tpm',
    meta: {
      title: '品鉴酒管理',
    },
    component: { render: (e) => e('router-view') },
    children: [
      {
        path: 'blending_policy_management',
        name: 'blending_policy_management',
        component: () => import(/* webpackChunkName: "tasting_wine_management/blending_policy_management" */ '../views/tasting_wine_management/blending_policy_management'),
        meta: { title: '品鉴配酒政策管理' },
      },
      {
        path: 'wine_mixing_pool',
        name: 'wine_mixing_pool',
        component: () => import(/* webpackChunkName: "tasting_wine_management/wine_mixing_pool" */ '../views/tasting_wine_management/wine_mixing_pool'),
        meta: { title: '品鉴配酒池' },
      },
      {
        path: 'tasting_wine_order',
        name: 'tasting_wine_order',
        component: () => import(/* webpackChunkName: "tasting_wine_management/tasting_wine_order" */ '../views/tasting_wine_management/tasting_wine_order'),
        meta: { title: '品鉴酒订单' },
      },
      {
        path: 'tasting_wine_order_management',
        name: 'tasting_wine_order_management',
        component: () => import(/* webpackChunkName: "tasting_wine_management/tasting_wine_order_management" */ '../views/tasting_wine_management/tasting_wine_order_management'),
        meta: { title: '品鉴酒订单管理' },
      },
      {
        path: 'bottle_opening_scanning_code',
        name: 'bottle_opening_scanning_code',
        component: () => import(/* webpackChunkName: "tasting_wine_management/bottle_opening_scanning_code" */ '../views/tasting_wine_management/bottle_opening_scanning_code'),
        meta: { title: '品鉴酒开瓶扫码配置' },
      },
      {
        path: 'price_maintenance',
        name: 'price_maintenance',
        component: () => import(/* webpackChunkName: "tasting_wine_management/price_maintenance" */ '../views/tasting_wine_management/price_maintenance'),
        meta: { title: '品鉴酒价格维护' },
      },
    ],
  },
  {
    path: '/tpm_center/report_form',
    name: 'tpm',
    meta: {
      title: '报表',
    },
    component: { render: (e) => e('router-view') },
    children: [
      {
        path: 'tpmDepartmentProfitloss',
        name: 'tpmDepartmentProfitloss',
        component: () => import(
          /* webpackChunkName: "report_form/department_profitloss" */ '../views/report_form/department_profitloss'
        ),
        meta: { title: '部门损益' },
      },
      {
        path: 'tpmProductProfitloss',
        name: 'tpmProductProfitloss',
        component: () => import(
          /* webpackChunkName: "report_form/product_profitloss" */ '../views/report_form/product_profitloss'
        ),
        meta: { title: '产品损益' },
      },
      {
        path: 'tpmCustomerProfitloss',
        name: 'tpmCustomerProfitloss',
        component: () => import(
          /* webpackChunkName: "report_form/customer_profitloss" */ '../views/report_form/customer_profitloss'
        ),
        meta: { title: '客户损益' },
      },

      {
        path: 'marketing_promote_detail',
        name: 'marketing_promote_detail',
        component: () => import(
          /* webpackChunkName: "report_form/marketing_activity_detail" */ '../views/report_form/marketing_promote_detail'
        ),
        meta: { title: '市场推广活动明细表' },
      },

      {
        path: 'marketing_activity_detail',
        name: 'marketing_activity_detail',
        component: () => import(
          /* webpackChunkName: "report_form/marketing_activity_detail" */ '../views/report_form/marketing_activity_detail'
        ),
        meta: { title: '市场活动明细表' },
      },

      {
        path: 'department_receiving_detail',
        name: 'department_receiving_detail',
        component: () => import(
          /* webpackChunkName: "report_form/department_receiving_detail" */ '../views/report_form/department_receiving_detail'
        ),
        meta: { title: '职能部门领用活动明细表' },
      },
      {
        path: 'activity_audit_detail',
        name: 'activity_audit_detail',
        component: () => import(
          /* webpackChunkName: "report_form/activity_audit_detail" */ '../views/report_form/activity_audit_detail'
        ),
        meta: { title: '活动核销明细表' },
      },
      {
        path: 'budget_input',
        name: 'budget_input',
        component: () => import(
          /* webpackChunkName: "report_form/budget_input" */ '../views/report_form/budget_input'
        ),
        meta: { title: '预算投入表' },
      },
      {
        path: 'Branch_activity_query',
        name: 'Branch_activity_query',
        component: () => import(
          /* webpackChunkName: "report_form/Branch_activity_query" */ '../views/report_form/Branch_activity_query'
        ),
        meta: { title: '公司主体分公司经销商活动查询报表' },
      },
      {
        path: 'branch_office_activity',
        name: 'branch_office_activity',
        component: () => import(
          /* webpackChunkName: "report_form/branch_office_activity" */ '../views/report_form/branch_office_activity'
        ),
        meta: { title: '公司主体分公司活动分类统计表' },
      },
      {
        path: 'Branch_activity_budget',
        name: 'Branch_activity_budget',
        component: () => import(
          /* webpackChunkName: "report_form/Branch_activity_budget" */ '../views/report_form/Branch_activity_budget'
        ),
        meta: { title: '分公司活动预算科目统计表' },
      },
      {
        path: 'cancel_obtaining_evidence',
        name: 'cancel_obtaining_evidence',
        component: () => import(
          /* webpackChunkName: "report_form/cancel_obtaining_evidence" */ '../views/report_form/cancel_obtaining_evidence'
        ),
        meta: { title: '取消执行查看报表' },
      },
    ],
  },
];
