<script>
import TablePage from '../table_page';
import request from '../../utils/request.js';

const tableConfig = {
  border: true,
  treeConfig: {
    children: 'children',
  },
  resizable: true, // 所有的列是否允许拖动列宽调整大小
  showOverflow: true, // 当内容过长时显示为省略号
  keepSource: false, // 保持原始值的状态，被某些功能所依赖，比如编辑状态、还原数据等（开启后影响性能，具体取决于数据量）
  highlightHoverRow: true, // 鼠标滑动高亮
  height: '380',
  tablePage: {
    // 分页
    pageSize: 10,
  },
};

export default {
  extends: TablePage,
  components: {},
  props: {
    formSelectConfig: Object,
    // functionCode: String,
    // selectionList: Array,
    // idKey: String,
    // hasChild: String,
    // paramData: Object,
    // choiceType: {
    //   type: String,
    //   default: 'checkbox',
    // },
  },
  data() {
    return {
      selectionList: [],
      configs: {
        tableConfig,
        columns: [],
      },
      params: {},
      isCalculateHeight: false,
    };
  },
  watch: {
    formSelectConfig(val) {
      this.requestUrl = val.requestUrl;
      // get请求列表数据
      if (val.requestType === 'GET') {
        this.getList = this.getoOurList;
      }
      this.params = {
        ...this.params,
        ...val.paramData,
      };
      this.configs.tableConfig = {
        ...this.configs.tableConfig,
        idKey: val.idKey,
      };
      this.configs.tableConfig.treeConfig.expandAll = val.expandAll;
      this.getConfigList(val.functionCode, true, true, val.parentCode).then(
        (res) => {
          this.selectRow = val.selectionList;
          this.selection = val.selectionList.map((a) => a[val.idKey]);
          this.configs.columns = res;
        },
      );
    },
  },
  created() {},
  methods: {
    getoOurList() {
      // const params = {};
      const { searchFormData, tablePage } = this;
      // console.log('搜索数据', searchFormData);
      const params = {
        ...this.params,
        ...searchFormData,
        pageNum: tablePage.currentPage,
        pageSize: tablePage.pageSize,
      };
      params.page = this.tablePage.currentPage;
      params.size = this.tablePage.pageSize;
      request
        .get(
          this.requestUrl,
          {
            ...params,
          },
          { headers: { functionCode: this.headerFunctionCode } },
        )
        .then((res) => {
          if (res.success) {
            this.tableLoading = false;
            const resData = res.result.records || res.result.data;
            if (resData) {
              this.tableData = resData;
              this.tablePage.totalResult = res.result.total || res.result.count;
            } else if (resData.length >= 0) {
              this.tableData = resData;
            }
            this.afterGetList();
            this.setSelectData();
          }
        });
    },
    setColumn(v) {
      let rowData = v;
      if (rowData.field === this.formSelectConfig.hasChild) {
        rowData.treeNode = true;
      } else if (
        rowData.field === 'CheckType'
        && rowData.field !== this.formSelectConfig.choiceType
      ) {
        rowData.type = this.formSelectConfig.choiceType;
      }
      if (this.formSelectConfig.setColumn) {
        rowData = this.formSelectConfig.setColumn(rowData);
      }
      return rowData;
    },
    afterGetList() {
      if (this.formSelectConfig.afterGetList) {
        this.tableData = this.formSelectConfig.afterGetList(this.tableData);
      }
      return true;
    },
    afterRadioChange() {
      // this.selectionList = this.selectRow;
      this.$emit('change', this.selectRow);
    },
    afterCheckboxChange() {
      // this.selectionList = this.selectRow;
      this.$emit('change', this.selectRow);
    },
    afterCheckAllChange() {
      // this.selectionList = this.selectRow;
      this.$emit('change', this.selectRow);
    },
    afterClickCheck() {
      this.$emit('change', this.selectRow);
    },
  },
};
</script>
